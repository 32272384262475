import { render, staticRenderFns } from "./ModuleVideoForm.vue?vue&type=template&id=60d0d8db&scoped=true"
import script from "./ModuleVideoForm.vue?vue&type=script&lang=js"
export * from "./ModuleVideoForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d0d8db",
  null
  
)

export default component.exports