var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Panomax Webcam auswählen")]),_c('div',{staticClass:"select is-link"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCam),expression:"selectedCam"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCam=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.update]}},_vm._l((_vm.cams),function(cam,index){return _c('option',{key:cam.camId,domProps:{"value":cam}},[_vm._v(_vm._s(cam.camId)+" "+_vm._s(cam.name)+" "+_vm._s(cam.customerName)+" ")])}),0)])]),_c('div',{staticClass:"mt-5 box"},[_c('p',[_c('strong',[_vm._v("Name : ")]),_vm._v(" "+_vm._s(_vm.selectedCam.name)+" ")]),_c('p',[_c('strong',[_vm._v("Zusatz : ")]),_vm._v(" "+_vm._s(_vm.selectedCam.customerName)+" ")]),_c('p',[_c('strong',[_vm._v("Kamera ID : ")]),_vm._v(" "+_vm._s(_vm.selectedCam.camId)+" ")])]),_c('div',{staticStyle:{"width":"640px","height":"360px","overflow":"hidden"}},[_c('img',{staticClass:"preview",style:({

            animationDuration: `${_vm.animationDuration}s`,
            animationDirection: `${_vm.animationDirection}`,
            height:`100%`,
            maxWidth:`100%`,
            objectFit:`cover`,
            objectPosition:`0%`,
          }),attrs:{"src":_vm.selectedCam.url}})]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Animations Dauer (s)")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.animationDuration),expression:"animationDuration",modifiers:{"number":true}}],staticClass:"input",attrs:{"type":"number"},domProps:{"value":(_vm.animationDuration)},on:{"change":_vm.update,"input":function($event){if($event.target.composing)return;_vm.animationDuration=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Animations Richtung ")]),_c('div',{staticClass:"select is-link"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.animationDirection),expression:"animationDirection"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.animationDirection=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.update]}},[_c('option',{attrs:{"value":"normal"}},[_vm._v("Vorwärts")]),_c('option',{attrs:{"value":"reverse"}},[_vm._v("Rückwerts")]),_c('option',{attrs:{"value":"alternate"}},[_vm._v("Vorwärts Hin und Her")]),_c('option',{attrs:{"value":"alternate-reverse"}},[_vm._v("Rückwerts Hin und Her")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }