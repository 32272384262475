var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.availableAssets.length > 0)?_c('div',{staticClass:"section is-paddingless has-text-right",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"buttons is-right"},[_c('a',{staticClass:"button is-black",class:{'is-loading':_vm.isCreatingNewAsset},on:{"click":_vm.createNewAsset}},[_vm._m(0),_c('strong',[_vm._v(_vm._s(_vm.string.addbutton[_vm.assetType]))])]),(_vm.assetType === 'page')?_c('a',{staticClass:"button is-link",class:{'is-loading':_vm.isCreatingNewAsset},attrs:{"disabled":""},on:{"click":_vm.createNewAssetFromTemplate}},[_vm._m(1),_c('strong',[_vm._v("Von Vorlage erstellen")])]):_vm._e()])]):_vm._e(),(!_vm.isLoading)?[(_vm.availableAssets.length > 0)?[_c('div',{staticClass:"asset-list-top"},[_c('p',{staticClass:"title is-5"},[_vm._v(_vm._s(_vm.string.title[_vm.assetType]))]),_vm._m(2),_c('asset-list-table',{attrs:{"availableAssets":_vm.availableAssets,"options":{edit:true, delete:true, mobilepage:_vm.assetType == 'mobile-page'}}})],1),_c('hr'),_c('div',{staticClass:"asset-list-bottom"},[_c('p',{staticClass:"title is-5"},[_vm._v(_vm._s(_vm.string.title2[_vm.assetType]))]),_vm._m(3),_c('asset-list-table',{attrs:{"availableAssets":_vm.availableMemberAssets,"options":{edit:true, delete:false, mobilepage:_vm.assetType == 'mobile-page'}}})],1)]:[_c('div',{staticClass:"section",staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c('a',{staticClass:"button is-outlined is-black",class:{'is-loading':_vm.isCreatingNewAsset},staticStyle:{"margin-bottom":"100px"},on:{"click":_vm.createNewAsset}},[_vm._m(4),_c('strong',[_vm._v(_vm._s(_vm.string.addbutton2[_vm.assetType]))])]),_vm._m(5)])]]:_c('loading-indicator')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-7"},[_vm._v("Deine persönlichen Inhalte. Du kannst sie bearbeiten,"),_c('br'),_vm._v("löschen und ander User hinzufügen.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-7"},[_vm._v("Du bist Mitglied dieser Inhalte. Du kannst sie"),_c('br'),_vm._v("bearbeiten aber nicht löschen.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"figure"},[_c('img',{attrs:{"src":"/assets/women_grass_tree.png"}})])
}]

export { render, staticRenderFns }